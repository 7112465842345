import "./styles/testimonials.css";
import {db} from "../../data/firebase";
import {TestimonialCard} from "./testimonialcard";
import Loader from "react-loader-spinner";
import {useUser} from "../../store/userStore";
import {AddTestimonyCard} from "./AddTestimonyCard";
import {useEffect, useState} from "react";

export const Testimonials = () => {
    const { user } = useUser();
    const [testimonyList, testimonyListSet] = useState([
        {
            id: "",
            testimony: "",
            customer: "",
            company: "",
        },
    ]);

    const [testimonyListLoad, testimonyListLoadSet] = useState(true);

    useEffect(() => {
        return db
            .collection("userData")
            .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
            .collection("testimonialListData")
            .orderBy("timeStamp", "desc")
            .onSnapshot((snapshot) => {
                testimonyListSet(
                    snapshot.docs.map((doc) => ({
                        id: doc.id,
                        testimony: doc.data().currentTestimony,
                        customer: doc.data().testimonyCustomer,
                        company: doc.data().testimonyCompany,
                    }))
                );
                testimonyListLoadSet(false);
            });
    }, []);

    return (
        <main className="testimonial">
            <div className="testimonial__contain">
                <hr />

                <div className="testimonial__list">
                    <h1 className="testimonial__heading">Testimonials</h1>
                    <div>
                        {testimonyListLoad && (
                            <div className="routes__loader">
                                <Loader
                                    type="TailSpin"
                                    color="#6396ab"
                                    height={80}
                                    width={80}
                                    timeout={3000}
                                />
                            </div>
                        )}
                        <div className="testimonial__grid">
                            {testimonyList.length > 0 &&
                            !testimonyListLoad && testimonyList.map((testimony) => (
                                <div key={testimony.id} className="testimonialgrid__container">
                                    <TestimonialCard id={testimony.id} testimony={testimony.testimony}  company={testimony.company} customer={testimony.customer} />
                                </div>
                            ))}

                            {user && <AddTestimonyCard/>}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
