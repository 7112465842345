import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import "./styles/removal-testimony-icon.css";

import { RemoveTestimonial } from "../RemoveTestimonial";

export const RemovalTestimonyIcon = ({ id, testimony}: {
    id: string;
    testimony: string;
}) => {
    const [isOpen, isOpenSet] = useState(false);

    return (
        <>
            <button className="removalTestimonyIcon" onClick={() => isOpenSet(true)}>
                <FaTrash />
            </button>
            <RemoveTestimonial
                open={isOpen}
                onClose={() => isOpenSet(false)}
                id={id}
                testimony ={testimony}
            />
        </>
    );
};
