import "./styles/edit.css";

import { useState } from "react";
import { createPortal } from "react-dom";

import { RiCloseFill } from "react-icons/ri";
import { BoxedMessages } from "../../../../../components/BoxedMessages";
import { db } from "../../../../../data/firebase";

export const Edit = ({
  id,
  initialTitleProp,
  initialPicUrlProp,
  open,
  onClose,
}: {
  id: string;
  initialTitleProp: string;
  initialPicUrlProp: string;
  open: boolean;
  onClose: () => void;
}) => {
  const initialState = {
    picUrl: initialPicUrlProp,
    title: initialTitleProp,
    imageLoaded: false
  };
  const [{picUrl, title, imageLoaded}, setState] = useState(initialState);
  const [errorDisplay, errorDisplaySet] = useState("");

  const clearState = () => {
    setState({ ...initialState });
  };

  function closePanel() {
    setTimeout(() => {
      clearState();
    }, 1000);
    onClose();
  }

  if (!open) return null;

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    try {
        await db
          .collection("userData")
          .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
          .collection("galleryData")
          .doc(id)
          .update({
            picUrl,
            title
          });
      onClose();
    } catch (error) {
      errorDisplaySet(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };



  return createPortal(
      <>
        <div className="edit">
          <div className="edit__card">
            <div className="edit__header">
              <div className="edit__headerLeft">
                <h1 className="edit__title">Edit: {initialTitleProp}</h1>
              </div>
              <div className="edit__headerRight">
                <button className="edit__closeButton" onClick={closePanel}>
                  <RiCloseFill />
                </button>
              </div>
            </div>
            <div className="edit__body">
              <p>Paste a self hosted image URL here</p>
              <div className="editInputContainer">
                <form onSubmit={(evt) => handleSubmit(evt)} noValidate>
                  <input
                          type="text"
                          name="picUrl"
                          id="imageUrl"
                          className="edit__urlText"
                          value={picUrl}
                          onChange={handleChange}
                          required
                          placeholder="Image URL"
                      />
                        <img
                            src={picUrl}
                            onLoad={() => setState((prevState) => ({ ...prevState,
                              imageLoaded: true }))}
                            onError={() => setState((prevState) => ({ ...prevState,
                              imageLoaded: false }))}
                            alt=""
                            className="edit__imagePreview"
                        />

                    {imageLoaded ? (
                        <label
                            className="edit__imageTitleLabel"
                            htmlFor="imageTitle"
                        >
                          <p> Caption/Title</p>
                              <input
                                  type="text"
                                  name="title"
                                  id="imageTitle"
                                  className="edit__imageTitle"
                                  value={title}
                                  onChange={handleChange}
                                  required
                                  placeholder="Caption/Title"
                                  maxLength={25}
                              />
                        </label>
                    ) : (
                        <BoxedMessages messageType="error">
                          Couldn't load image
                        </BoxedMessages>
                    )}
                    {errorDisplay && (
                        <BoxedMessages messageType="error">
                          {errorDisplay}
                        </BoxedMessages>
                    )}
                  {picUrl.length > 0 ? (
                      <button
                          disabled={
                            !imageLoaded
                          }
                      >
                        Update
                      </button>
                  ) : (
                      <button
                          disabled={
                            !imageLoaded ||
                            title.length < 1
                          }
                      >
                        Update
                      </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>,
      document.getElementById("portal")!
  );
};
