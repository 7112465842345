import "./styles/edit-vid.css";

import { useState } from "react";
import { createPortal } from "react-dom";

import { RiCloseFill } from "react-icons/ri";
import { BoxedMessages } from "../../../../components/BoxedMessages";
import { db } from "../../../../data/firebase";

export const EditVid = ({
  id,
  initialTitleProp,
  initialVideoIDProp,
  open,
  onClose,
}: {
  id: string;
  initialTitleProp: string;
  initialVideoIDProp: string;
  open: boolean;
  onClose: () => void;
}) => {
  const initialState = {
    videoID: initialVideoIDProp,
    title: initialTitleProp,
    videoLoaded: false
  };
  const [{videoID, title, videoLoaded}, setState] = useState(initialState);
  const [errorDisplay, errorDisplaySet] = useState("");

  const clearState = () => {
    setState({ ...initialState });
  };

  function closePanel() {
    setTimeout(() => {
      clearState();
    }, 1000);
    onClose();
  }

  if (!open) return null;

  async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    try {
        await db
          .collection("userData")
          .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
          .collection("videoListData")
          .doc(id)
          .update({
            videoID,
            title
          });
      onClose();
    } catch (error) {
      errorDisplaySet(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return createPortal(
    <>
      <div className="edit">
        <div className="edit__card">
          <div className="edit__header">
            <div className="edit__headerLeft">
              <h1 className="edit__title">Edit: {initialTitleProp}</h1>
            </div>
            <div className="edit__headerRight">
              <button className="edit__closeButton" onClick={closePanel}>
                <RiCloseFill />
              </button>
            </div>
          </div>
          <div className="edit__body">
            <p>Paste a YouTube video ID here</p>
            <div className="editInputContainer">
              <form onSubmit={(evt) => handleSubmit(evt)} noValidate>
                  <input
                    type="text"
                    name="videoID"
                    id="videoID"
                    className="edit__urlText"
                    value={videoID}
                    onChange={handleChange}
                    required
                    placeholder="YouTube Video ID"
                  />
                    <img
                      src={`https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`}
                      onLoad={() => setState((prevState) => ({ ...prevState,
                        videoLoaded: true }))}
                      onError={() => setState((prevState) => ({ ...prevState,
                        videoLoaded: false }))}
                      alt=""
                      className="edit__imagePreview"
                    />


                  {videoLoaded ? (
                    <label
                      className="edit__videoTitleLabel"
                      htmlFor="videoTitle"
                    >
                      <p>Video Title</p>
                        <input
                          type="text"
                          name="title"
                          id="videoTitle"
                          className="edit__videoTitle"
                          value={title}
                          onChange={handleChange}
                          required
                          placeholder="Video Title"
                        />

                    </label>
                  ) : (
                    <BoxedMessages messageType="error">
                      Couldn't load image
                    </BoxedMessages>
                  )}
                  {errorDisplay && (
                    <BoxedMessages messageType="error">
                      {errorDisplay}
                    </BoxedMessages>
                  )}

                {title.length > 0 ? (
                  <button
                    disabled={
                      !videoLoaded
                      // ||
                      // currentVideo.length < 1 ||
                      // initialVideoTitle.length < 1
                    }
                  >
                    Update
                  </button>
                ) : (
                  <button
                    disabled={
                      !videoLoaded ||
                      // currentVideo.length < 1 ||
                      title.length < 1
                    }
                  >
                    Update
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")!
  );
};
