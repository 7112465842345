import "./styles/testimonialcard.css";
import { FaQuoteLeft,FaQuoteRight } from "react-icons/fa";
import {useUser} from "../../../store/userStore";
import {EditTestimonyIcon} from "../EditTestimonyIcon";
import {RemovalTestimonyIcon} from "../RemovalTestimonyIcon";


export const TestimonialCard = ({id, testimony, company, customer }: { id: string, testimony: string, company?:string, customer?: string }) => {

    const { user } = useUser();

    return (
            <div className="testimonial__card__wrapper">
                <div className="testimonial__card">
                    {user && (
                        <div className="testimonial__cardButtonContainer">
                            <EditTestimonyIcon
                                id={id}
                                testimony={testimony}
                                company={company}
                                customer={customer}
                            />

                            <RemovalTestimonyIcon id={id} testimony={testimony}/>
                        </div>
                    )}
                    <div className="testimonial__card__body">
                        <i className="fa__quote fa-quote-left"><FaQuoteLeft/></i>
                        <p>
                            &nbsp;&nbsp;
                            {testimony}
                        </p>
                        <i className="fa__quote fa-quote-right"><FaQuoteRight/></i>
                    </div>
                </div>

                <div className="testimonial__card__footer">
                    { company && customer ? <h3>{company} / {customer} </h3>
                        : !customer && company ? (<h3>{company}</h3>)
                            : <h3>{customer && customer}</h3>}
                </div>
            </div>
    );
};
