import { useState } from "react";
import { createPortal } from "react-dom";

import { RiCloseFill } from "react-icons/ri";
import { BoxedMessages } from "../../../components/BoxedMessages";
import { db } from "../../../data/firebase";

export const EditTestimony = ({ id, testimonyProp, companyProp, customerProp, open, onClose, }: {
    id: string;
    testimonyProp: string;
    companyProp?: string;
    customerProp?: string;
    open: boolean;
    onClose: () => void;
}) => {
    const initialState = {
        currentTestimony: testimonyProp,
        testimonyCompany: companyProp,
        testimonyCustomer: customerProp,
    };

    const [{currentTestimony, testimonyCompany, testimonyCustomer}, setState] = useState(initialState);
    const [errorDisplay, errorDisplaySet] = useState("");

    const clearState = () => {
        setState({ ...initialState });
    };

    function closePanel() {
        setTimeout(() => {
            clearState();
        }, 1000);
        onClose();
    }

    if (!open) return null;

    async function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault();
        try {
            await db
                .collection("userData")
                .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
                .collection("testimonialListData")
                .doc(id)
                .update({
                    currentTestimony,
                    testimonyCompany,
                    testimonyCustomer
                });
            onClose();
        } catch (error) {
            errorDisplaySet(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>  | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };



    return createPortal(
        <>
            <div className="edit">
                <div className="edit__card">
                    <div className="edit__header">
                        <div className="edit__headerLeft">
                            <h1 className="edit__title">Edit Testimonial</h1>
                        </div>
                        <div className="edit__headerRight">
                            <button className="edit__closeButton" onClick={closePanel}>
                                <RiCloseFill />
                            </button>
                        </div>
                    </div>
                    <div className="edit__body">
                        <p>Paste testimonial here</p>
                        <div className="editInputContainer">
                            <form onSubmit={(evt) => handleSubmit(evt)} noValidate>
                                <textarea
                                    name="currentTestimony"
                                    id="currentTestimony"
                                    className="editAboutMe__urlText"
                                    value={currentTestimony}
                                    onChange={handleChange}
                                    required
                                    placeholder="Testimonial"
                                    rows={8}
                                    cols={69}
                                    maxLength={600}
                                />
                                <label
                                    className="add__videoTitleLabel"
                                    htmlFor="testimonyCompany"
                                >
                                    <p>Company</p>
                                    <input
                                        type="text"
                                        name="testimonyCompany"
                                        id="testimonyCompany"
                                        className="add__videoTitle"
                                        value={testimonyCompany}
                                        onChange={handleChange}
                                        required
                                        placeholder="Company"
                                    />
                                </label>

                                <label
                                    className="add__videoTitleLabel"
                                    htmlFor="testimonyCompany"
                                >
                                    <p>Customer</p>
                                    <input
                                        type="text"
                                        name="testimonyCustomer"
                                        id="testimonyCustomer"
                                        className="add__videoTitle"
                                        value={testimonyCustomer}
                                        onChange={handleChange}
                                        required
                                        placeholder="Customer"
                                    />
                                </label>

                                {errorDisplay && (
                                    <BoxedMessages messageType="error">
                                        {errorDisplay}
                                    </BoxedMessages>
                                )}

                                    <button
                                        disabled={
                                            currentTestimony.length < 1
                                        }
                                    >
                                        Update
                                    </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>,
        document.getElementById("portal")!
    );
};
