import {createPortal} from "react-dom";
import "./styles/edit-info.css";
import {db} from "../../../../data/firebase";
import {RiCloseFill} from "react-icons/ri";
import {BoxedMessages} from "../../../BoxedMessages";
import {useEffect, useState} from "react";

interface EditInfoProps {
  open: boolean;
  onClose: () => void;
}

const initialState = {
  fullName:"",
  title: "",
  email: "",
  youtube: "",
  twitch: "",
  twitter: "",
  facebook: "",
  instagram: "",
  contactBanner: "",
  showTestimonials: "false",
};

export const EditInfo = ({ open, onClose }: EditInfoProps) => {
  const [
    { fullName, title, email, youtube, twitch, twitter, facebook, instagram,contactBanner,showTestimonials },
    setState
  ] = useState(initialState);
  const [errorDisplay, errorDisplaySet] = useState("");

  const clearState = () => {
    setState({ ...initialState });
  };


  useEffect(() => {
    return db
        .collection("userData")
        .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
        .onSnapshot((doc) => {
          setState({
            fullName: doc.data()?.fullName,
            title: doc.data()?.title,
            email: doc.data()?.email,
            youtube: doc.data()?.youtube,
            twitch: doc.data()?.twitch,
            twitter: doc.data()?.twitter,
            facebook: doc.data()?.facebook,
            instagram: doc.data()?.instagram,
            contactBanner: doc.data()?.contactBanner,
            showTestimonials: doc.data()?.showTestimonials
          })
        });
  }, [onClose]);

  const closePanel = () => {
    setTimeout(() => {
      clearState();
    }, 1000);
    onClose();
  };

  if (!open) return null;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
        (await db
          .collection("userData")
          .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
          .update({
            fullName,
            title,
            email,
            contactBanner,
            youtube,
            twitch,
            twitter,
            facebook,
            instagram,
            showTestimonials
          }));

      closePanel();
    } catch (error) {
      errorDisplaySet(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };


  return createPortal(
      <>
        <div className="edit">
          <div className="edit__card">
            <div className="edit__header">
              <div className="edit__headerLeft">
                <h1 className="edit__title">Edit portfolio information</h1>
              </div>
              <div className="edit__headerRight">
                <button className="edit__closeButton" onClick={closePanel}>
                  <RiCloseFill />
                </button>
              </div>
            </div>
            <div className="edit__body">
              <div className="editInputContainer">
                <form onSubmit={(event) => handleSubmit(event)} noValidate>

                  <label htmlFor="fullName">Full Name*</label>
                      <input
                          type="text"
                          id="fullName"
                          className="edit__urlText"
                          value={fullName}
                          name="fullName"
                          onChange={handleChange}
                          required
                          placeholder="Full Name"
                      />

                  <label htmlFor="title">
                    Professional Headline* - One line about you
                  </label>
                      <input
                          type="text"
                          id="title"
                          className="edit__urlText"
                          value={title}
                          name={"title"}
                          onChange={handleChange}
                          required
                          placeholder="Title"
                      />

                  <label htmlFor="email">Contact Email*</label>
                  <input
                          type="email"
                          id="email"
                          className="edit__urlText"
                          value={email}
                          name={"email"}
                          onChange={handleChange}
                          required
                          placeholder="Contact Email"
                      />

                  <label htmlFor="youtube">YouTube Link</label>
                      <input
                          type="text"
                          id="youtube"
                          className="edit__urlText"
                          value={youtube}
                          name={"youtube"}
                          onChange={handleChange}
                          placeholder="YouTube Link"
                      />

                  <label htmlFor="twitch">Twitch Link</label>
                      <input
                          type="text"
                          id="twitch"
                          className="edit__urlText"
                          value={twitch}
                          name={"twitch"}
                          onChange={handleChange}
                          placeholder="Twitch Link"
                      />

                  <label htmlFor="twitter">Twitter Link</label>
                      <input
                          type="text"
                          id="twitter"
                          className="edit__urlText"
                          value={twitter}
                          name="twitter"
                          onChange={handleChange}
                          placeholder="Twitter Link"
                      />

                  <label htmlFor="facebook">Facebook Link</label>
                      <input
                          type="text"
                          id="facebook"
                          className="edit__urlText"
                          value={facebook}
                          name="facebook"
                          onChange={handleChange}
                          placeholder="Facebook Link"
                      />

                  <label htmlFor="instagram">Instagram Link</label>
                      <input
                          type="text"
                          id="instagram"
                          className="edit__urlText"
                          value={instagram}
                          name="instagram"
                          onChange={handleChange}
                          placeholder="Instagram Link"
                      />

                  <label htmlFor="contact-banner">Contact Banner URL</label>
                      <input
                          type="text"
                          id="contact-banner"
                          className="edit__urlText"
                          value={contactBanner}
                          name="contactBanner"
                          onChange={handleChange}
                          placeholder="Contact Banner URL"
                      />

                  <label htmlFor="show-testimonials-group">Show Testimonial Page?</label>
                  <div className="edit__urlText">
                    <input
                        type="radio"
                        id="show-testimonials-yes"
                        name="showTestimonials"
                        value="true"
                        checked={showTestimonials === "true"}
                        onChange={handleChange}
                    />
                    <label htmlFor="show-testimonials-yes"> Yes</label><br/>
                    <input
                        type="radio"
                        id="show-testimonials-no"
                        name="showTestimonials"
                        value="false"
                        checked={showTestimonials === "false"}
                        onChange={handleChange}
                    />
                    <label htmlFor="show-testimonials-no"> No</label><br/>
                  </div>

                  {errorDisplay.length > 0 && (
                      <BoxedMessages messageType="error">
                        {errorDisplay}
                      </BoxedMessages>
                  )}

                  {fullName.length > 0 ||
                  title.length > 0 ||
                  email.length > 0 ||
                  contactBanner.length > 0 ? (
                      <button>Update</button>
                  ) : (
                      <button
                          disabled={
                            fullName.length < 1 ||
                            title.length < 1 ||
                            email.length < 1 ||
                            contactBanner.length < 1
                          }
                      >
                        Update
                      </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>,

      document.getElementById("portal")!
  );
};