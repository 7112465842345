import firebase from "firebase/app";
import { useState } from "react";

import { createPortal } from "react-dom";
import { RiCloseFill } from "react-icons/ri";
import { BoxedMessages } from "../../../components/BoxedMessages";
import { db } from "../../../data/firebase";

import "./styles/add-testimony.css";

const initialState = {
    currentTestimony: "",
    testimonyCompany: "",
    testimonyCustomer: ""
}

export const AddTestimony = ({
                                 open,
                                 onClose,
                             }: {
    open: boolean;
    onClose: () => void;
}) => {
    const [{currentTestimony, testimonyCompany, testimonyCustomer}, setState] = useState(initialState);
    const [errorDisplay, errorDisplaySet] = useState("");

    if (!open) return null;

    const clearState = () => {
        setState({ ...initialState });
    };

    function closePanel() {
        setTimeout(() => {
            clearState();
        }, 1000);
        onClose();
    }

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        try {
            await db
                .collection("userData")
                .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
                .collection("testimonialListData")
                .add({
                    currentTestimony,
                    testimonyCompany,
                    testimonyCustomer,
                    timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                });

            closePanel();
        } catch (error) {
            errorDisplaySet(error);
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>  | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        console.log("name: ", name, "value: ", value)
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    return createPortal(
        <>
            <div className="add">
                <div className="add__card">
                    <div className="add__header">
                        <div className="add__headerLeft">
                            <h1 className="add__title">New Testimonial</h1>
                        </div>
                        <div className="add__headerRight">
                            <button className="add__closeButton" onClick={closePanel}>
                                <RiCloseFill />
                            </button>
                        </div>
                    </div>
                    <div className="add__body">
                        <p>Paste a Testimonial here*</p>
                        <div className="addInputContainer">
                            <form onSubmit={(evt) => handleSubmit(evt)} noValidate>
                                 <textarea
                                     id="currentTestimony"
                                     className="editAboutMe__urlText"
                                     name="currentTestimony"
                                     value={currentTestimony}
                                     onChange={handleChange}
                                     required
                                     placeholder="Testimonial"
                                     rows={8}
                                     cols={69}
                                     maxLength={600}
                                 />

                                <label
                                    className="add__videoTitleLabel"
                                    htmlFor="testimonyCompany"
                                >
                                    <p>Company</p>
                                    <input
                                        type="text"
                                        name="testimonyCompany"
                                        id="testimonyCompany"
                                        className="add__videoTitle"
                                        value={testimonyCompany}
                                        onChange={handleChange}
                                        required
                                        placeholder="Company"
                                    />
                                </label>

                                <label
                                    className="add__videoTitleLabel"
                                    htmlFor="testimonyCompany"
                                >
                                    <p>Customer</p>
                                    <input
                                        type="text"
                                        name="testimonyCustomer"
                                        id="testimonyCustomer"
                                        className="add__videoTitle"
                                        value={testimonyCustomer}
                                        onChange={handleChange}
                                        required
                                        placeholder="Customer"
                                    />
                                </label>

                                {errorDisplay.length > 0 && (
                                    <BoxedMessages messageType="error">
                                        {errorDisplay}
                                    </BoxedMessages>
                                )}


                                <button
                                    disabled={
                                        currentTestimony.length < 1
                                    }
                                >

                                    Save
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>,
        document.getElementById("portal")!
    );
};
