import "./styles/navbar.css";
import { NavLink } from "react-router-dom";
import { FaDoorOpen } from "react-icons/fa";

import { useUser } from "../../store/userStore";
import { EditInfoButton } from "./EditInfoButton";
import {useEffect, useState} from "react";
import {db} from "../../data/firebase";

export const Navbar = () => {
  const { user, signout } = useUser();
  const [showTestimonialPage, showTestimonialPageSet] = useState<boolean>();
  const [showTestimonialString, showTestimonialStringSet] = useState("");

    useEffect(() => {
        return db
            .collection("userData")
            .doc("vREP4xBazXgx9C3iOopEHi9yA8t2")
            .onSnapshot((doc) => {
               showTestimonialStringSet(doc.data()?.showTestimonials);

                if (showTestimonialString === "true")
                    showTestimonialPageSet(true);
                else
                    showTestimonialPageSet(false);
            });
       
    }, [showTestimonialString, showTestimonialPage]);

  return (<>
    <nav className="navbar">
      <ul className="navbar__linkWrapper">
        {user ? (
          <>
            <div className="navbar__loggedinLeft">
              <NavLink
                exact
                to="/"
                className="navbar__link"
                activeClassName="navbar__activeLink"
              >
                <li className="navbar__item">
                  <span className="navbar__linkText">Portfolio</span>
                </li>
              </NavLink>
              <NavLink
                exact
                to="/episodes"
                className="navbar__link"
                activeClassName="navbar__activeLink"
              >
                <li className="navbar__item">
                  <span className="navbar__linkText">Episodes</span>
                </li>
              </NavLink>
              <NavLink
                exact
                to="/about"
                className="navbar__link"
                activeClassName="navbar__activeLink"
              >
                <li className="navbar__item">
                  <span className="navbar__linkText">About</span>
                </li>
              </NavLink>
              <NavLink
                exact
                to="/contact"
                className="navbar__link"
                activeClassName="navbar__activeLink"
              >
                <li className="navbar__item">
                  <span className="navbar__linkText">Contact</span>
                </li>
              </NavLink>
                <NavLink
                    exact
                    to="/testimonials"
                    className="navbar__link"
                    activeClassName="navbar__activeLink"
                >
                    <li className="navbar__item">
                        <span className="navbar__linkText">Testimonials</span>
                    </li>
                </NavLink>

            </div>
            <EditInfoButton />

            <button
              className="navbar__adminExit"
              onClick={() => {
                signout();
              }}
            >
              <FaDoorOpen color="white" size="30" />
            </button>
          </>
        ) : (
          <div className="navbar__left">
            <NavLink
              exact
              to="/"
              className="navbar__link"
              activeClassName="navbar__activeLink"
            >
              <li className="navbar__item">
                <span className="navbar__linkText">Portfolio</span>
              </li>
            </NavLink>
            <NavLink
              exact
              to="/episodes"
              className="navbar__link"
              activeClassName="navbar__activeLink"
            >
              <li className="navbar__item">
                <span className="navbar__linkText">Episodes</span>
              </li>
            </NavLink>
            <NavLink
              exact
              to="/about"
              className="navbar__link"
              activeClassName="navbar__activeLink"
            >
              <li className="navbar__item">
                <span className="navbar__linkText">About</span>
              </li>
            </NavLink>
            <NavLink
              exact
              to="/contact"
              className="navbar__link"
              activeClassName="navbar__activeLink"
            >
              <li className="navbar__item">
                <span className="navbar__linkText">Contact</span>
              </li>
            </NavLink>

              {showTestimonialPage && <NavLink
                  exact
                  to="/testimonials"
                  className="navbar__link"
                  activeClassName="navbar__activeLink"
              >
                  <li className="navbar__item">
                      <span className="navbar__linkText">Testimonials</span>
                  </li>
              </NavLink>}
          </div>
        )}
      </ul>
    </nav>
      </>
  );
};
