import { useState } from "react";
import { FaPlusSquare } from "react-icons/fa";
import "./styles/add-testimony-card.css";

import {AddTestimony} from "../AddTestimony";

export const AddTestimonyCard = () => {
    const [isOpen, isOpenSet] = useState(false);

    return (
        <div className="addTestimonyCard__container">
            <button className="addTestimonyCard" onClick={() => isOpenSet(true)}>
                <FaPlusSquare size="28" />
            </button>
            <AddTestimony open={isOpen} onClose={() => isOpenSet(false)} />
        </div>
    );
};
