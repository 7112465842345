import { useState } from "react";

import { FaPencilAlt } from "react-icons/fa";
import { EditTestimony } from "../EditTestimony";
import "./styles/edit-testimony-icon.css";

export const EditTestimonyIcon = ({ id, testimony, company, customer}:{
    id: string;
    testimony: string;
    company?: string;
    customer?: string;
}) => {
    const [isOpen, isOpenSet] = useState(false);

    return (
        <>
            <button className="editTestimonyIcon" onClick={() => isOpenSet(true)}>
                <FaPencilAlt />
            </button>
            <EditTestimony
                id={id}
                testimonyProp = {testimony}
                companyProp = {company}
                customerProp = {customer}
                open={isOpen}
                onClose={() => isOpenSet(false)}
            />
        </>
    );
};
